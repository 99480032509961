<template>
  <div>
    <div style="margin-bottom: 20px">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item >强算子管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-card shadow="always" class="list-container">
      <div class="header">
        <div class="header__title">强算子列表</div>
        <div class="header__btn" @click="dialogVisible = true">
          + 新增强算子
        </div>
      </div>
      <div class="content">
        <el-table :data="tableData" :header-cell-style="{
            'text-align': 'center',
            'font-weight': 'bold',
            color: '#333',
          }" :cell-style="{ 'text-align': 'center', color: '#333' }" style="width: 100%">
          <el-table-column type="index" label="序号" width="100" />
          <el-table-column prop="name" label="强算子名称" />
          <el-table-column prop="stockCode" label="强算子代码" />
          <el-table-column prop="stockCode" label="强算子类型">
            <template #default="scope">
              <span v-show="scope.row.type == 1">股票</span>
              <span v-show="scope.row.type == 2">指数</span>
            </template>
          </el-table-column>
          <el-table-column prop="addTime" label="添加时间" />
          <el-table-column prop="addUser" label="添加人" />
          <el-table-column label="操作">
            <template #default="scope">
              <el-button @click="deleteOperate(scope.row)" type="text" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="page">
          <el-pagination v-model:currentPage="currentPage" :page-sizes="[5, 10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
    </el-card>

    <el-dialog title="新增强算子" v-model="dialogVisible" :width="520">
      <el-form ref="form" label-width="120px" class="stock-form">
        <el-form-item label="股票代码或名称">
          <div class="stockInfo" style="width: 450px">
            <el-input v-model.trim="stockInfo" maxlength="20" style="width: 280px"></el-input>
            <el-button style="margin-left: 10px" @click="checkStockInfo">校验</el-button>
          </div>
          <div class="stockState">{{ stockState }}</div>
        </el-form-item>
        <el-form-item label="强算子类型">
          <el-select v-model="value" placeholder="请选择" style="width: 100%">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="info" @click="confirmNode" style="background-color:#38a28a">提 交</el-button>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      policyTitle: "",
      publishDate: [],
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      total: 0,
      dialogVisible: false,
      stockInfo: "",
      options: [],
      value: "",
      stockState: "",
      stockForm: {},
    });
    watch(
      () => state.value,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          state.stockState = "";
        }
      }
    );
    watch(
      () => state.dialogVisible,
      (val) => {
        if (!val) {
          state.stockState = "";
          state.stockInfo = "";
          state.value = "";
        }
      }
    );

    onMounted(() => {
      getList();
      getType();
    });

    const getList = () => {
      let data = {
        pageNo: state.currentPage,
        pageSize: state.pageSize,
      };
      proxy
        .$post("/cts/back/strongOperator/page", data)
        .then((res) => {
          if (res.resultState === "200") {
            state.tableData = res.data.list;
            state.total = res.data.total;
          }
        })
        .catch((err) => { });
    };

    const handleSizeChange = (val) => {
      state.pageSize = val;
      getList();
    };

    const handleCurrentChange = (val) => {
      state.currentPage = val;
      getList();
    };

    const addPolicy = () => {
      router.push({ path: "/content/add" });
    };

    const getType = () => {
      proxy
        .$get("/cts/back/strongOperator/getType")
        .then((res) => {
          if (res.resultState === "200") {
            state.options = res.data;
          }
        })
        .catch((err) => { });
    };
    const checkStockInfo = () => {
      if (!state.stockInfo) {
        ElMessage({
          message: "请输入股票代码或名称",
          type: "warning",
        });
        return;
      }
      let data = {
        param: state.stockInfo,
        type: state.value,
      };
      proxy
        .$post("/cts/back/strongOperator/validate", data)
        .then((res) => {
          if (res.resultState === "200") {
            state.stockState = "校验成功";
            state.stockForm = res.data;
          }
        })
        .catch((err) => { });
    };
    const confirmNode = () => {
      if (!state.stockInfo) {
        ElMessage({
          message: "请输入股票代码或名称",
          type: "warning",
        });
        return;
      }
      if (!state.value) {
        ElMessage({
          message: "请选择强算子类型",
          type: "warning",
        });
        return;
      }
      if (!state.stockState) {
        ElMessage({
          message: "请先校验",
          type: "warning",
        });
        return;
      }
      if (state.stockInfo && !state.stockState) {
        ElMessageBox.alert("请校验股票代码或名称", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      state.stockForm["type"] = state.value;
      console.log(state.stockForm);
      proxy
        .$post("/cts/back/strongOperator/add", state.stockForm)
        .then((res) => {
          if (res.resultState === "200") {
            ElMessage({
              message: `${res.msg}`,
              type: "success",
            });
            state.dialogVisible = !state.dialogVisible;
            state.stockInfo = "";
            state.value = "";
            state.stockState = "";
            getList();
          }
        })
        .catch((err) => { });
    };
    const deleteOperate = (data) => {
      ElMessageBox.alert("确认删除该条数据吗?", "提示", {
        confirmButtonText: "确定",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: data.id,
          };
          proxy
            .$post("/cts/back/strongOperator/delete", params)
            .then((res) => {
              if (res.resultState === "200") {
                ElMessage({
                  message: `${res.msg}`,
                  type: "success",
                });
                getList();
              }
            })
            .catch((err) => { });
        })
        .catch(() => { });
      return;
    };
    return {
      ...toRefs(state),
      handleSizeChange,
      handleCurrentChange,
      addPolicy,
      deleteOperate,
      confirmNode,
      getType,
      checkStockInfo,
    };
  },
};
</script>

<style lang="scss" scoped>
.container-title {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0 20px 0;
}
.title {
  font-weight: bold;
}
.search-content {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search-box {
    display: flex;
    align-items: center;
    &__title {
      width: 130px;
    }
  }
  .search-btn {
    background: rgb(56, 162, 138);
    width: 80px;
    padding: 8px 0;
    border-radius: 6px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
  }
}
.list-container {
  margin-top: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    &__title {
      font-weight: bold;
    }
    &__btn {
      background: rgb(56, 162, 138);
      width: 110px;
      padding: 8px 0;
      border-radius: 6px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      font-weight: bold;
    }
  }
  .content {
    margin-top: 15px;
    .page {
      float: right;
      margin: 20px;
    }
  }
}
::v-deep .el-dialog {
  padding-bottom: 40px;
}
::v-deep .el-dialog__body {
  padding: 20px 30px;
  padding-bottom: 0;
  .stockInfo {
    display: flex;
  }
  .stockState {
    color: #38a28a;
    font-weight: bold;
  }
  .stock-form {
    width: 400px;
  }
}
::v-deep .el-dialog__footer {
  padding: 10px 40px;
  text-align: left;
}
</style>
